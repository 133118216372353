import * as React from "react";
import { observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { useStores } from "../../../stores";

import {
  makeStyles,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { PageHead } from "../../../components/PageHead";
import { ServiceStore } from "../service.store";
import CloseIcon from "@material-ui/icons/Close";

const serviceStore = new ServiceStore();

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: "calc(50% - 300px)",
    top: "20%",
  },
}));

interface IProps {
  history: any;
  match?: any;
}

export const ServicePageDetail = observer((props: IProps) => {
  const [id, setId] = React.useState<number>();
  const [success, setSuccess] = React.useState(false);

  const { appStore } = useStores();
  const classes = useStyles();

  const init = async () => {
    const { id } = props.match.params;
    setId(id);

    serviceStore.page.loading = true;

    appStore.setMenu("expense.create");
    serviceStore.init();
    await serviceStore.fetchLookups();

    if (id) {
      await serviceStore.fetch(id);
    }

    serviceStore.page.loading = false;
  };

  const onSuccessClose = () => {
    setSuccess(false);
    props.history.push(`/service/${id}/detail`);
  };

  React.useEffect(() => {
    init();
  }, [appStore, props.match.params]);

  const { model } = serviceStore.data as any;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Layout history={props.history} loading={serviceStore.page.loading}>
        <PageHead title="KAYIT DETAY">
          <ButtonGroup color="primary">
            <Button onClick={() => props.history.push("/service")}>
              Vazgeç
            </Button>
            <Button
              onClick={async () => {
                const result = await serviceStore.save();
                setId(result.id);
                setSuccess(true);
              }}
            >
              Kaydet
            </Button>
          </ButtonGroup>
        </PageHead>

        <form>
          <Card style={{ marginBottom: 15 }} variant="outlined">
            <CardHeader title="Tekne Bilgileri"></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Model</InputLabel>
                    <Select
                      value={model.model.id || ""}
                      onChange={(e) => {
                        model.model.id = e.target.value;
                      }}
                    >
                      {serviceStore.data.lookup.models.map(
                        (item: any, i: number) => (
                          <MenuItem value={item.id} key={i}>
                            {item.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Yıl"
                    value={model.model_year || ""}
                    onChange={(e) => (model.model_year = e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Tekenin Durumu</InputLabel>
                    <Select
                      value={model.model_status || ""}
                      onChange={(e) => {
                        model.model_status = e.target.value;
                      }}
                    >
                      <MenuItem value={"New"}>Sıfır</MenuItem>
                      <MenuItem value={"Used"}>İkinci El</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Lokasyon"
                    value={model.location || ""}
                    onChange={(e) => (model.location = e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Hull Number"
                    value={model.hull_number || ""}
                    onChange={(e) => (model.hull_number = e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 15 }} variant="outlined">
            <CardHeader title="Tekne Sahibi"></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Ad"
                    value={model.owner_name || ""}
                    onChange={(e) => (model.owner_name = e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Soyad"
                    value={model.owner_lastname || ""}
                    onChange={(e) => (model.owner_lastname = e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    value={model.owner_email || ""}
                    onChange={(e) => (model.owner_email = e.target.value)}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    label="Telefon"
                    value={model.owner_phone || ""}
                    onChange={(e) => (model.owner_phone = e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ marginBottom: 15 }} variant="outlined">
            <CardHeader title="Kayıt Bilgileri"></CardHeader>
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Kayıt Tipi</InputLabel>
                    <Select
                      value={model.record_type || ""}
                      onChange={(e) => {
                        model.record_type = e.target.value;
                      }}
                    >
                      <MenuItem value={"Service"}>Servis - Onarım</MenuItem>
                      <MenuItem value={"Special"}>Özel Talep</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <KeyboardDatePicker
                    style={{ marginTop: 0 }}
                    disableToolbar
                    fullWidth
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Kayıt Tarihi"
                    value={model.record_date}
                    onChange={(date) => (model.record_date = date)}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={10}
                    label="Talep Detayı"
                    value={model.record_content || ""}
                    onChange={(e) => (model.record_content = e.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {id && (
            <Card style={{ marginBottom: 15 }} variant="outlined">
              <CardHeader title="Kayıt Oluşturan Kişi"></CardHeader>
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      label="Ad"
                      disabled
                      value={model.user.firstname || ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      label="Soyad"
                      disabled
                      value={model.user.lastname || ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      disabled
                      value={model.user.email || ""}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      label="Telefon"
                      disabled
                      value={model.user.phone || ""}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </form>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={success}
          autoHideDuration={6000}
          onClose={onSuccessClose}
          message="Başarıyla Kayıt Oldu"
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={onSuccessClose}>
                Tamam
              </Button>
              <IconButton size="small" color="inherit" onClick={onSuccessClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Layout>
    </MuiPickersUtilsProvider>
  );
});
