import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Dashboard } from "./modules/dashboard/Dashboard";
import { Login } from "./modules/auth/Login";
import { Logout } from "./modules/auth/Logout";
import { AccountRoutes } from "./modules/account/account.module";
import { ServiceRoutes } from "./modules/service/service.module";
import { NotFound } from "./modules/error/NotFound";

const App = () => {
  React.useEffect(() => {
    // load app config
  }, []);

  return (
    <Router>
      <AccountRoutes />
      <ServiceRoutes />
      <Switch>
        <Route path="/" exact={true} component={Dashboard}></Route>
        <Route path="/auth/login" component={Login}></Route>
        <Route path="/auth/logout" component={Logout}></Route>
      </Switch>
    </Router>
  );
};

export default App;
