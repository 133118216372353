import React from "react";
import { Card, CardHeader, Grid } from "@material-ui/core";
import { useStores } from "../../../stores";
import { SortableTable } from "../../../components/sortabletable/SortableTable";
import { IHeaderProps } from "../../../components/sortabletable/SortableTableHead";
import { Layout } from "../../../components/Layout";

interface IProps {
  history: any;
}

export const ServicePageDashboard = (props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {
    appStore.setMenu("service.dashboard");
  }, [appStore]);

  const headers: IHeaderProps[] = [
    {
      name: "created_at",
      text: "Tarih",
      sortable: true,
      align: "left",
      type: "date",
    },
    {
      name: "model.name",
      text: "Model",
      sortable: true,
    },
    {
      name: "hull_number",
      text: "Hull Number",
      sortable: true,
    },
    {
      name: "record_type",
      text: "Kayıt Tipi",
      sortable: true,
    },
    {
      name: "owner_name",
      text: "Adı",
      sortable: true,
    },
    {
      name: "owner_lastname",
      text: "Soyadı",
      sortable: true,
    },
  ];

  return (
    <Layout history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Servis Kayıtları"></CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <SortableTable
            endpoint="/service-records"
            sort="created_at"
            onEditClicked={(id: number) =>
              props.history.push("/service/" + id + "/detail")
            }
            headers={headers}
          ></SortableTable>
        </Grid>
      </Grid>
    </Layout>
  );
};
