import axios from "axios";
import { action, makeAutoObservable } from "mobx";
import { api } from "../../services/api.service";

export class ServiceStore {
  page = {
    loading: true,
  };

  data = {
    lookup: {
      models: [],
    },
    model: {
      model: {},
      model_year: undefined,
      model_status: undefined,
      location: undefined,
      hull_number: undefined,
      owner_name: undefined,
      owner_lastname: undefined,
      owner_email: undefined,
      owner_phone: undefined,
      record_type: undefined,
      record_date: undefined,
      record_content: undefined,
      status: undefined,
      approval_date: undefined,
      user: {},
    } as any,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    console.log("Initializing Service Store");

    // Reset Data
    this.page = {
      loading: true,
    };

    this.data = {
      lookup: {
        models: [],
      },
      model: {
        model: {},
        model_year: undefined,
        model_status: undefined,
        location: undefined,
        hull_number: undefined,
        owner_name: undefined,
        owner_lastname: undefined,
        owner_email: undefined,
        owner_phone: undefined,
        record_type: undefined,
        record_date: undefined,
        record_content: undefined,
        status: undefined,
        approval_date: undefined,
        user: {},
      },
    };
  };

  @action fetchLookups = async () => {
    const models = await api.get("/models");
    this.data.lookup.models = models.data;
  };

  @action fetch = async (id: number) => {
    const result = await api.get("/service-records/" + id);
    this.data.model = result.data;
  };

  @action save = async () => {
    this.page.loading = true;

    var formdata = new FormData();

    formdata.set("data", JSON.stringify(this.data.model));
    // formdata.set("files.attachment", this.data.model.attachment);

    if (this.data.model.id) {
      const result = await api.put(
        "/service-records/" + this.data.model.id,
        formdata
      );
      this.page.loading = false;
      return result.data;
    } else {
      const result = await api.post("/service-records", formdata);
      this.page.loading = false;
      return result.data;
    }
  };
}
