import * as React from "react";
import {
  createStyles,
  TableCell,
  TableRow,
  TableSortLabel,
  Theme,
  withStyles,
} from "@material-ui/core";

export type Order = "asc" | "desc";
export type IColumnType = "string" | "number" | "date" | "currency";

export interface IHeaderProps {
  name: string;
  text: string;
  sortable?: boolean;
  align?: "center" | "left" | "right";
  type?: IColumnType;
  order?: Order;
  orderBy?: string;
  handleRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: string
  ) => void;
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 12,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export const SortableTableHead = (props: IHeaderProps) => {
  return props.sortable ? (
    <StyledTableCell
      sortDirection={props.orderBy === props.name ? props.order : false}
      align={props.align}
    >
      <TableSortLabel
        active={props.orderBy === props.name}
        direction={props.orderBy === props.name ? props.order : "asc"}
        onClick={(e) => props.handleRequestSort!(e, props.name)}
      >
        {props.text}
      </TableSortLabel>
    </StyledTableCell>
  ) : (
    <StyledTableCell align={props.align}>{props.text}</StyledTableCell>
  );
};
