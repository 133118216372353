import axios from "axios";
import { action, makeAutoObservable } from "mobx";

interface IUser {
  id?: Number;
  token?: string;
  username?: string;
  email?: string;
}

export default class AppStore {
  user: IUser = {};

  name: string = "Mini Crm";
  index: number = 1;
  menu = "";
  submenu: string | undefined = "";
  menuOpen = false;

  error = {
    has_error: false,
    message: "",
  };

  constructor() {
    console.log("Initializing App Store");
    makeAutoObservable(this);
  }

  @action setMenuOpen() {
    this.menuOpen = !this.menuOpen;
  }

  @action setMenu = (menu: string) => {
    var parts = menu.split(".");

    this.menu = parts[0];
    this.submenu = parts.length > 1 ? parts[1] : undefined;
  };

  @action checkUser = async () => {
    const userdata = localStorage.getItem("crm:user");

    if (userdata) {
      this.user = JSON.parse(userdata);
    }
  };

  @action login = async (username: string, password: string) => {
    this.logout();

    try {
      const { data } = await this.api.post("auth/local", {
        identifier: username,
        password: password,
      });

      let userdata = {
        token: data.jwt,
        id: data.user.id,
        email: data.user.email,
        username: data.user.username,
      };

      localStorage.setItem("crm:user", JSON.stringify(userdata));

      this.user = userdata;

      return data;
    } catch (e) {
      console.log(e);
      return "error";
    }
  };

  @action logout = async () => {
    localStorage.removeItem("crm:user");
    this.user = {};
  };

  get api() {
    let headers: any = {
      "Access-Control-Allow-Origin": "*",
    };

    if (this.user.token) {
      headers.Authorization = `Bearer ${this.user.token}`;
    }

    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers,
    });
  }
}
