import * as React from "react";
import { Route, Switch } from "react-router";
import { ServicePageDashboard } from "./pages/service.page.dashboard";
import { ServicePageDetail } from "./pages/service.page.detail";

interface IProps {
  subpath?: string;
}

export const ServiceRoutes = (props: IProps) => (
  <Switch>
    <Route path="/service" exact component={ServicePageDashboard}></Route>
    <Route path="/service/create" component={ServicePageDetail}></Route>
    <Route path="/service/:id/detail" component={ServicePageDetail}></Route>
  </Switch>
);
