import React from "react";
import { configure } from "mobx";

import AppStore from "./app.store";

configure({
  enforceActions: "never",
});

export const storesContext = React.createContext({
  appStore: new AppStore(),
});

export const useStores = () => React.useContext(storesContext);
