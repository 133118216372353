import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { Layout } from "../../../components/Layout";
import { Loading } from "../../../components/Loading";
import { useStores } from "../../../stores";
import { AccountStore } from "../account.store";

interface IProps {
  history: any;
}

const accountStore = new AccountStore();

export const AccountSettingsPage = observer((props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {
    appStore.setMenu("account.settings");
    accountStore.init();

    setTimeout(() => {
      accountStore.page.loading = false;
    }, 1000);
  }, [appStore]);

  return (
    <Layout history={props.history} loading={accountStore.page.loading}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Expense Dashboard"></CardHeader>
            <Divider />
            <CardContent>
              <Button onClick={(e) => appStore.logout().then(r => props.history.push("/"))}>Logout</Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
});
