import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import { useStores } from "../../stores";

export const Logout = observer((props: any) => {
  const { appStore } = useStores();
  
  React.useEffect(() => {
    appStore.logout();
  }, []);

  return <Redirect to="/" />;
});
