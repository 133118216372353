import { action, makeAutoObservable } from "mobx";

export class AccountStore {
  page = {
    loading: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  @action init = () => {
    // Reset Data
    this.page = {
      loading: true,
    };
  };
}
