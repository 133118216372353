import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { Navigation } from "./Navigation";
import { useStores } from "../stores";
import { Loading } from "./Loading";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  })
);

interface Props {
  window?: () => Window;
  history: any;
  children: React.ReactNode;
  loading?: boolean;
}

export const Layout = observer((props: Props) => {
  const classes = useStyles();
  const stores = useStores();

  React.useEffect(() => {
    stores.appStore.checkUser();
    if (!stores.appStore.user.token) props.history.push("/auth/login");
  }, [stores.appStore, props.history]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={stores.appStore.setMenuOpen}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            SERVİS KAYIT SİSTEMİ
          </Typography>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            <span style={{ fontSize: 11, marginRight: 10 }}>
              {stores.appStore.user.email}
            </span>
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Navigation />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.loading ? <Loading /> : props.children}
      </main>
    </div>
  );
});
