import { Paper } from "@material-ui/core";
import * as React from "react";

interface IPageHeadProps {
  title?: string;
  children?: any;
}

export const PageHead = (props: IPageHeadProps) => {
  return (
    <Paper
      variant="outlined"
      elevation={1}
      style={{
        padding: 15,
        marginBottom: 15,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <h3 style={{ margin: 0 }}>{props.title}</h3>
      {props.children}
    </Paper>
  );
};
