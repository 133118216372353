import * as React from "react";
import { Route, Switch } from "react-router";
import { AccountSettingsPage } from "./pages/settings.page";

interface IProps {
  subpath?: string;
}

export const AccountRoutes = (props: IProps) => (
  <Switch>
    <Route path="/account/settings" component={AccountSettingsPage}></Route>
  </Switch>
);
