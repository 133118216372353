import axios from "axios";

let user;
// fetch token
const userdata = localStorage.getItem("crm:user");

if (userdata) {
  user = JSON.parse(userdata);
}

// create instance
let headers: any = {
  "Access-Control-Allow-Origin": "*",
};

if (user) {
  headers.Authorization = `Bearer ${user.token}`;
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
  headers: headers,
});

export { api };
