import {
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { api } from "../../services/api.service";
import { currencyFormat } from "../../services/helper.service";
import {
  IHeaderProps,
  Order,
  SortableTableHead,
  StyledTableCell,
  StyledTableRow,
} from "./SortableTableHead";
import EditIcon from "@material-ui/icons/Visibility";
import { Loading } from "../Loading";
import moment from "moment";

interface ISortableTableProps {
  endpoint: string;
  sort?: string;
  headers: IHeaderProps[];
  onEditClicked: (id: number) => void;
  children?: any;
}

export const SortableTable = (props: ISortableTableProps) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState(props.sort);
  const [order, setOrder] = React.useState<Order>("desc");

  const fetchData = async (
    perPage: number,
    page: number,
    order?: string,
    orderBy?: string
  ) => {
    setLoading(true);

    const count_response = await api.get(props.endpoint + "/count");

    const params = {
      _start: page * perPage,
      _limit: perPage,
    } as any;

    if (orderBy) {
      params["_sort"] = `${orderBy}:${order}`;
    }

    const response = await api.get(props.endpoint, { params });

    setData(response.data);
    setCount(count_response.data);
    setLoading(false);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const direction = isAsc ? "desc" : "asc";
    setOrder(direction);
    setOrderBy(property);

    fetchData(rowsPerPage, page, direction, property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const perPage = parseInt(event.target.value, 10);
    setRowsPerPage(perPage);
    setPage(0);

    fetchData(perPage, 0, order, orderBy);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage, order, orderBy);
  };

  const getObjectValue = (obj: any, key: string) => {
    var arr = key.split(".");
    while (arr.length && (obj = obj[arr.shift() as string]));
    return obj;
  };

  const getRowData = (row: any, header: IHeaderProps) => {
    let value = getObjectValue(row, header.name);

    switch (header.type) {
      case "number":
        break;
      case "date":
        value = moment(value).format("DD/MM/yyyy");
        break;
      case "currency":
        value = currencyFormat(value, "EUR");
        break;
      default:
        break;
    }

    return value;
  };

  React.useEffect(() => {
    fetchData(rowsPerPage, page, order, orderBy);
  }, []);

  return (
    <div className="lg-sortableTable">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {props.headers.map((header: IHeaderProps, index: number) => (
                <SortableTableHead
                  key={index}
                  name={header.name}
                  text={header.text}
                  sortable={header.sortable}
                  order={order}
                  orderBy={orderBy}
                  handleRequestSort={handleRequestSort}
                />
              ))}
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <Loading />
          ) : (
            <TableBody>
              {data.map((row: any) => (
                <StyledTableRow key={row.id}>
                  {props.headers.map((header: IHeaderProps, index: number) => (
                    <StyledTableCell align={header.align} scope="row">
                      {getRowData(row, header)}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    <ButtonGroup size="small" variant="text">
                      <Button onClick={() => props.onEditClicked(row.id)}>
                        <EditIcon />
                      </Button>
                    </ButtonGroup>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};
