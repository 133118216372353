import { Layout } from "../../components/Layout";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@material-ui/core";
import React from "react";
import { useStores } from "../../stores";
import { SortableTable } from "../../components/sortabletable/SortableTable";
import { IHeaderProps } from "../../components/sortabletable/SortableTableHead";

interface IProps {
  history: any;
}

export const Dashboard = (props: IProps) => {
  const { appStore } = useStores();

  React.useEffect(() => {
    appStore.setMenu("dashboard");
  }, [appStore]);

  return (
    <Layout history={props.history}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Hoşgeldiniz!"></CardHeader>
            <CardContent>Nautique Servis Kayıt Sistemi v1.0</CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
